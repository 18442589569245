import { EngineSDK } from '@dilvant/engine-sdk';
import { SDK } from '@dilvant/bizcard-sdk';

import { log } from 'console';
import { countDown } from './js/countdown';
// import AOS from 'aos';

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// AOS.init();

SDK.setEnv('prod');
EngineSDK.setEnv('prod');

// const urlParams = new URLSearchParams(window.location.search);
// const eventId = urlParams.get("event");
// const guestId = urlParams.get("guest");
// getGuest(guestId);
// hideButtons();

countDown();

$('.autoplay').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	infinite: true,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
});

let containerGallery = document.getElementsByClassName('slick-track');
if (!containerGallery || containerGallery.length === 0) return;
containerGallery[0].classList.add('lightgallery');
containerGallery.id = 'lightgallery';

document.addEventListener('DOMContentLoaded', function () {
	lightGallery(containerGallery[0], {
		plugins: [lgZoom, lgThumbnail],
		licenseKey: 'your_license_key',
		speed: 500
	});
});

// function getGuest(guestId) {
// 	const apiUrl = `https://dilvant-engine-crm-2515a83e333b.herokuapp.com/api/event-guests/${guestId}`;

// 	fetch(apiUrl)
// 	.then(response => response.json())
// 	.then(data => {
// 		const { result } = data
// 			if (guestId) {
// 				showButtons();
// 				if (result.status === '652033a4625d6f0027b04d46' || result.status === '655fbfb9bdf11f002789b5f3') {
// 					document.querySelector("#confirmName").innerHTML = `<span style="color: #9D7A80; font-weight: 700;">${result.fullName},</span> presiona el botón para confirmar o declinar tu asistencia a nuestra boda ❤️<br>¡Muchas gracias!`;
// 				} else if (result.status === '652033c0625d6f0027b04d47'){
// 					document.querySelector("#confirmName").innerHTML = `¡<span style="color: #9D7A80; font-weight: 700;">${result.fullName},</span> parece que ya has confirmado tu invitación a nuestra boda, esperamos verte pronto! ❤️`;
// 					hideButtons();
// 				} else if (result.status === '650c5640dfc3ec79857331fb') {
// 					document.querySelector("#confirmName").innerHTML = `¡<span style="color: #9D7A80; font-weight: 700;">${result.fullName},</span> parece que ya has dicho que no asistarás a nuestra a nuestra boda 😔, De igual manera abajo está el botón de confirmar en caso de que hayas cambiado de opinión!`;
// 				}
// 			}

// 		})
// 		.catch(error => {
// 			console.error("Error al obtener datos:", error)
// 			hideButtons();
// 			document.querySelector("#confirmName").innerHTML = `Esta no es una invitación válida, contacta con los organizadores de la boda para que te envían una a tu correo 😁`;
// 		}
// 		);
// }

function showDialog() {
	confirmModal.style.display = 'flex';
	// const dialog = document.getElementById("gratitude-popup");
	// const overlay = document.getElementById("overlay");
	// overlay.style.display = "block"
	// dialog.style.display = "flex";
	document.querySelector(
		'#confirmName'
	).innerHTML = `¡Muchas gracias por haber confirmado tu invitación a nuestra boda, esperamos que disfrutes de este hermoso momento tanto como nosotros!`;
}

function showDeclineDialog() {
	declineModal.style.display = 'flex';
	// const declineDialog = document.getElementById("gratitude-popup-decline");
	// const overlay = document.getElementById("overlay");
	// overlay.style.display = "block"
	// declineDialog.style.display = "flex";
	document.querySelector(
		'#confirmName'
	).innerHTML = `Lamentamos que no puedas asistir. Si cambias de opinión, por favor contáctanos. ¡Deseamos encontrarnos en otra oportunidad!`;
}

function hideDialog() {
	confirmModal.style.display = 'none';
	declineModal.style.display = 'none';
	// const dialog = document.getElementById("gratitude-popup");
	// const declineDialog = document.getElementById("gratitude-popup-decline");
	// const overlay = document.getElementById("overlay");
	// overlay.style.display = "none"
	// dialog.style.display = "none";
	// declineDialog.style.display = "none";
}

export function hideButtons() {
	const confirmButton = document.getElementById('btnConfirmAsistance');
	// const declineButton = document.getElementById("btnDeclineAsistance");
	confirmButton.style.display = 'none';
	// declineButton.style.display = "none";
}

function showButtons() {
	const confirmButton = document.getElementById('btnConfirmAsistance');
	// const declineButton = document.getElementById("btnDeclineAsistance");
	confirmButton.style.display = 'block';
	// declineButton.style.display = "block";
}

// function processGuest(id, statusId, event) {

// 	fetch(`https://dilvant-engine-crm-2515a83e333b.herokuapp.com/api/event-guests/find/one?_id=${id}&event=${event}`)
// 		.then(response => response.json())
// 		.then(data => {

// 			if (data && data.result._id) {
// 				const { result } = data;
// 				const guestId = result._id;
// 				const apiUrl = `https://dilvant-engine-crm-2515a83e333b.herokuapp.com/api/event-guests/${guestId}`;
// 				const requestBody = {
// 					status: statusId
// 				};

// 				fetch(apiUrl, {
// 					method: "PUT",
// 					headers: {
// 						"Content-Type": "application/json"
// 					},
// 					body: JSON.stringify(requestBody)
// 				})
// 					.then(response => response.json())
// 					.then(data => {
// 						if (statusId === "652033c0625d6f0027b04d47") {
// 							showDialog();
// 						} else {
// 							showDeclineDialog();
// 						}
// 						hideButtons();
// 					}).catch(err => {
// 						console.log(err);
// 					})

// 			} else {
// 				alert("No se encontró al invitado con ese correo electrónico.");
// 			}
// 		})
// 		.catch(error => {
// 			alert("Error al obtener invitado");
// 		});
// }

const confirmButton = document.getElementById('btnConfirmAsistance');
// const declineButton = document.getElementById("btnDeclineAsistance");
const closeButton = document.getElementById('closeConfirmModal');
const closeButtonDecline = document.getElementById('closeDeclineModal');
const sendConfirmButton = document.getElementById('sendConfirmModal');
// const sendDeclineButton = document.getElementById('sendDeclineModal');
const confirmModal = document.getElementById('gratitude-modal');
confirmModal.style.display = 'none';
const declineModal = document.getElementById('gratitude-modal-decline');
declineModal.style.display = 'none';
const confirmForm = document.getElementById('#confirm-form');

// if (!confirmButton || !closeButton || !declineButton  || !closeButtonDecline) return;

confirmButton.addEventListener('click', () => {
	// showDialog();
	hideButtons();

	// processGuest(guestId, "652033c0625d6f0027b04d47", eventId);
});

// declineButton.addEventListener('click', () => {
// showDeclineDialog();
// hideButtons();
// processGuest(guestId, "650c5640dfc3ec79857331fb", eventId);
// });

closeButton.addEventListener('click', () => {
	hideDialog();
});

sendConfirmButton.addEventListener('click', () => {
	hideDialog();
});

// sendDeclineButton.addEventListener('click', () => {
// 	hideDialog();
// });

closeButtonDecline.addEventListener('click', () => {
	hideDialog();
});

// confirmForm.addEventListener('submit', e => {
// 	e.preventDefault();
// 	console.log('form enviado');
// });

// const btnOpenModalGift = document.getElementById('btnOpenModalGift');
// btnOpenModalGift.addEventListener('click', () => {

// });

// const closeModalTableGift = document.getElementById('closeModalTableGift');
// closeModalTableGift.addEventListener('click', () => {
// 	modalTableGift.style.display = 'none';
// });
